<template>
  <aside class="navbar" :class="{ collapsed: !menuOpen }">
    <div class="navbar-header">
      
    </div>
    <ul class="navbar-menu">
      <li>
        <router-link to="/lista-usuarios" @click="closeMenu">Gestión de invitados</router-link>
      </li>
      <li v-if="canView('administrador')">
        <router-link to="/eventos" @click="closeMenu">Gestión de eventos</router-link>
      </li>
      <li v-if="canView('superadministrador')">
        <router-link to="/buscador" @click="closeMenu">Buscador</router-link>
      </li>
      <li>
        <router-link :to="'/dashboard/user/edit/' + $store.state.user.token" @click="closeMenu">
          Editar mi perfil
        </router-link>
      </li>
      <li>
        <a href="#" @click="logoutAndClose">Cerrar sesión</a>
      </li>
    </ul>
  </aside>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    menuOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    ...mapActions(["logout"]),
    toggleMenu() {
      this.$emit("toggle-menu");
    },
    closeMenu() {
      this.$emit("close-menu");
    },
    logoutAndClose() {
      this.logout();
      this.closeMenu();
    },
    canView(...expectedRoles) {
      const roleHierarchy = {
        usuario: 1,
        administrador: 2,
        administradorevento: 3,
        superadministrador: 4,
      };
      return expectedRoles.some(
        (role) => roleHierarchy[this.$store.state.role] >= roleHierarchy[role]
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.navbar
  width: 16rem
  background-color: #1d3557
  color: white
  height: 100vh
  position: absolute
  // top: 4rem
  left: 0
  transition: all 0.2s
  overflow-x: hidden
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1)

  &.collapsed
    left: -16rem
    // overflow: hidden

  .navbar-header
    display: flex
    justify-content: space-between
    align-items: center
    padding: 1rem
    background-color: #457b9d
    font-size: 1.2rem
    font-weight: bold
    min-height: 4rem

    .close-btn
      background: none
      border: none
      color: white
      font-size: 1.5rem
      cursor: pointer

  .navbar-menu
    list-style: none
    padding: 1rem
    margin: 0

    li
      margin-bottom: 1rem

      a
        color: white
        text-decoration: none
        font-size: 1rem
        padding: 0.5rem 1rem
        display: block
        border-radius: 4px

        &:hover
          background-color: #f4a261
</style>
