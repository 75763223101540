<template>
  <div class="container">
    <!-- Header -->
    <div class="card">
      <div class="card-title">
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <i class="icon mdi mdi-chat"></i>
            <h2 class="font-weight-bold text-h6 ml-2">Vue Chatapp</h2>
          </div>
          <button @click="leaveRoom" class="btn btn-primary text-capitalize">
            Leave {{ room }}
          </button>
        </div>
      </div>

      <hr>

      <!-- Body -->
      <div class="card-body">
        <div class="d-flex">
          <!-- Sidebar -->
          <div class="sidebar">
            <div class="room-info">
              <div class="room-name">
                <i class="icon mdi mdi-chat-outline"></i>
                <span class="ml-2">Room Name</span>
              </div>
              <p class="current-room">{{ currentRoom }}</p>
            </div>

            <div class="users-list-title">
              <i class="icon mdi mdi-account-group-outline"></i>
              <span class="ml-2">Users</span>
            </div>

            <ul class="users-list">
              <li v-for="(user, i) in users" :key="i">
                {{ user.username }}
              </li>
            </ul>
          </div>

          <!-- Chat -->
          <div class="chat-box">
            <div 
              v-for="(chat, i) in chats" 
              :key="i" 
              class="chat-message"
              :class="{
                'admin-message': chat.username === 'Vue Chatapp Admin',
                'user-message': chat.username === username,
                'other-user-message': chat.username !== username && chat.username !== 'Vue Chatapp Admin'
              }"
            >
              <div class="message-header">
                <span class="chat-username">{{ chat.username }}</span>
                <span class="chat-time">{{ chat.time }}</span>
              </div>
              <p class="chat-text">{{ chat.text }}</p>
            </div>
          </div>
        </div>
      </div>

      <hr>

      <!-- Footer -->
      <div class="card-footer">
        <form @submit.prevent="onSubmit">
          <input 
            v-model="message" 
            type="text" 
            placeholder="Type a message" 
            class="input-message"
          />
          <button type="submit" class="btn-send">Send</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { io } from 'socket.io-client';

export default {
  name: 'ChatRoom',
  setup() {
    const route = useRoute();
    const router = useRouter();

    // Variables reactivas
    const message = ref('');
    const chats = ref([]);
    const users = ref([]);
    const currentRoom = ref('');
    const socket = ref(null);

    // Parámetros de la URL
    const username = route.query.username;
    const room = route.query.room;

    /**
     * Enviar un mensaje al servidor de Socket.io
     */
    const onSubmit = () => {
      if (message.value.trim()) {
        socket.value.emit('chatMessage', message.value);
        message.value = '';
      }
    };

    /**
     * Dejar la sala de chat y redirigir a la página de inicio
     */
    const leaveRoom = () => {
      router.push('/');
    };

    /**
     * Conectar al servidor de Socket.io
     */
    onMounted(() => {
      socket.value = io('https://websockets.gestiontotal.app/', {
        reconnection: true,            // Habilita la reconexión automática
        reconnectionAttempts: 10,      // Número máximo de intentos de reconexión
        reconnectionDelay: 1000,       // Tiempo de espera entre intentos (en milisegundos)
        reconnectionDelayMax: 5000,    // Tiempo máximo de espera entre intentos (en milisegundos)
        timeout: 20000,                // Tiempo máximo de espera para conectar inicialmente (en milisegundos)
        autoConnect: true              // Inicia la conexión automáticamente
      });


      if (!username || !room) {
        router.push('/');
        return;
      }

      socket.value.emit('joinRoom', { username, room });

        // Reconectar al cambiar la visibilidad de la página
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible' && !socket.value.connected) {
          console.log('Reconectando al servidor...');
          socket.value.connect(); // Reconectar manualmente
        }
      });
       // Eventos
       socket.value.on('connect', () => {
          console.log('Conexión establecida');
          if (!users.value.find(user => user.username === username)) {
            socket.value.emit('joinRoom', { username, room });
          }
        });

      socket.value.on('disconnect', (reason) => {
          console.warn('Conexión perdida:', reason);

          // Intentar reconectar manualmente si no se conecta automáticamente
          if (reason === 'io server disconnect') {
            console.log('El servidor desconectó la sesión. Intentando reconectar...');
            socket.value.connect(); // Reconecta manualmente si fue una desconexión del servidor
          }
        });


        socket.value.on('reconnect', (attempt) => {
          console.log(`Reconectado en el intento ${attempt}`);
          if (!users.value.find(user => user.username === username)) {
            socket.value.emit('joinRoom', { username, room });
          }
        });

      socket.value.on('roomUsers', ({ room, users: roomUsers }) => {
        users.value = roomUsers;
        currentRoom.value = room;
      });

      socket.value.on('message', (message) => {
        chats.value.push(message);
      });
    });

    /**
     * Desconectar de la sala de chat antes de salir del componente
     */
    onBeforeUnmount(() => {
      socket.value.disconnect();
    });

    // Retornar todas las variables y métodos utilizados en la plantilla
    return {
      message,
      chats,
      users,
      currentRoom,
      onSubmit,
      leaveRoom,
      username,
      room
    };
  }
};
</script>


<style scoped>
/* General Styles */
.d-flex {
display: flex;
}
.align-center {

  align-items: center;
}

.justify-space-between{

  justify-content: space-between;
  width: 100%;
}
  

.container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.card {
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.card-body {
  display: flex;
  width: 100%;
  min-width: 800px;
  flex-wrap: nowrap;
}
.card-body .d-flex {
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-wrap: wrap;
}

.sidebar {
  width: 30%;
  height: 100%;
  background-color: #f4f4f4;
  padding: 20px;
}

.room-info {
  margin-bottom: 20px;
}

.room-name {
  display: flex;
  align-items: center;
}

.users-list-title {
  margin-top: 20px;
}

.users-list {
  list-style: none;
  padding: 0;
}

.users-list li {
  padding: 10px 0;
}

.chat-box {
  flex-grow: 1;
  width: 70%;
  /* background: red; */
  overflow-y: auto;
  padding: 20px;
}

.chat-message {
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 10px;
  max-width: 50%;
}

.admin-message {
  background-color: #fce4ec;
}

.user-message {
  background-color: #e3f2fd;
}

.other-user-message {
  background-color: #c8e6c9;
}

.message-header {
  display: flex;
  justify-content: space-between;
}

.chat-username {
  font-weight: bold;
}

.chat-time {
  font-size: 12px;
}

.input-message {
  width: 80%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

.btn-send {
  padding: 10px 20px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-send:hover {
  background-color: #1565c0;
}

.btn-primary {
  background-color: #1976d2;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #1565c0;
}

.icon {
  font-size: 24px;
}

.text-h6 {
  font-size: 18px;
  font-weight: bold;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-weight-bold {
  font-weight: bold;
}

.text-primary {
  color: #1976d2;
}
.admin-message {
  margin-right: auto;
  margin-left: auto;
  
}

.other-user-message {
  margin-right: auto;
  
}
.user-message{
  margin-left: auto;
}
</style>
