<template>
  <div class="appvue">
    <TopBar v-if="showNav" @toggle-sidebar="toggleMenu" />
    <div class="app-container">
      
      <NavBar v-if="showNav" :menuOpen="menuOpen" @close-menu="menuOpen = false" />
      
      <main :class="[{ main: showNav }, { 'main-expanded': !menuOpen && showNav }]">
        <router-view class="content-page" />
      </main>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/navbar/NavBar.vue';
import TopBar from '@/components/navbar/TopBar.vue';

export default {
  components: {
    NavBar,
    TopBar
  },
  data() {
    return {
      menuOpen: true,
    };
  },
  computed: {
    // Verifica la ruta actual y decide si mostrar o no la navegación
    showNav() {
      // Lista de rutas donde no se mostrará el nav
      // const hideNavRoutes = ['/login', '/registrarse', '/solicitar-recuperacion', '/expired-recovery','/cambiar_pass'];
      const hideNavRoutes = [
        /^\/login$/,
        /^\/registrarse(\/.*)?$/, 
        /^\/solicitar-recuperacion$/,
        /^\/expired-recovery$/,
        /^\/cambiar_pass$/,
        /^\/magic-link-login(\/[\w-]+)?$/
      ];
      // Verifica también si la página actual es una 404
      // return !hideNavRoutes.includes(this.$route.path) && this.$route.name !== 'NotFound';
      const currentPath = this.$route.path;
      const isRouteHidden = hideNavRoutes.some((regex) => regex.test(currentPath));
      return !isRouteHidden && this.$route.name !== 'NotFound';
    }
  },
    methods: {
      toggleMenu() {
        this.menuOpen = !this.menuOpen;
      },
      closeMenu() {
        this.menuOpen = false;
      },
      handleResize() {
        // Ocultar barra lateral si el ancho es menor a 992px (tablet o móvil)
        this.menuOpen = window.innerWidth >= 992;
      },
    },
    mounted() {
      // Configurar el estado inicial basado en el tamaño de la pantalla
      this.handleResize();

      // Escuchar eventos de cambio de tamaño
      window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
      // Limpiar el listener al destruir el componente
      window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.content-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 70px; /* Ajusta este valor según la altura de tu NavBar */
}
.container {
  padding-top: 80px!important; /* Ajusta este valor según la altura de tu NavBar */

}
nav {
  padding: 30px;
  width: 100%;
  
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.appvue{
  display: flex;
  flex-direction: column;
}


.main {
  width: 100%;
  margin-left: 16rem;
  transition: margin-left 0.3s;
}

.main-expanded{
  margin-left: 0;

}
.app-container{
  display: flex;
}
</style>
