<template>
    <header class="topbar">
      <div class="topbar-content">
        <button class="menu-toggle" @click="$emit('toggle-sidebar')">
          <span class="menu-icon">&#9776;</span>
        </button>
        <div class="topbar-title">Gestión Total</div>
        <div class="topbar-actions">
          <a href="#" class="icon-button">🔔</a>
          <a href="#" class="icon-button">❓</a>
          <a href="#" class="icon-button">👤</a>
        </div>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: "TopBar",
  };
  </script>
  
  <style lang="sass" scoped>
  .topbar
    width: 100%
    background-color: #16314A
    color: white
    position: fixed
    top: 0
    left: 0
    z-index: 1000
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
  
    .topbar-content
      display: flex
      align-items: center
      justify-content: space-between
      padding: 0.5rem 1rem
      height: 4rem
  
      .menu-toggle
        background: none
        border: none
        color: white
        font-size: 1.5rem
        cursor: pointer
  
      .topbar-title
        font-size: 1.2rem
        font-weight: bold
  
      .topbar-actions
        display: flex
        gap: 1rem
  
        .icon-button
          color: white
          font-size: 1.2rem
          text-decoration: none
          cursor: pointer
          &:hover
            color: #f4a261
  </style>
  