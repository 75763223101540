<template>
  <div class="login-page">
    <div class="login-container">
      <div class="left-panel">
        <h1 class="logo">GESTION TOTAL <span>ADMIN</span></h1>
      </div>
      <div class="right-panel">
        <div class="contenedor-panel-right">
          <h2 class="title">Iniciar sesión</h2>
          <p class="subtitle">
            ¿Nuevo en Gestion Total? <a @click="goToRegister" class="link">Regístrate</a>
          </p>
          <form @submit.prevent="handleFormSubmit" class="login-form">
            <div class="form-group">
              <label for="email">Correo electrónico</label>
              <input
                id="email"
                v-model="email"
                type="email"
                placeholder="ejemplo@mail.com"
                @input="resetAnimation"
                @keyup.enter="startEmailAnimation"
              />
            </div>
            <div class="form-group">
              <label for="password">Contraseña</label>
              <input
                id="password"
                v-model="password"
                type="password"
                placeholder="********"
                @keyup.enter="handleLogin"
              />
            </div>
            <div class="form-footer">
              <!-- <div class="checkbox-group">
                <input id="remember" type="checkbox" />
                <label for="remember">Mantener sesión iniciada</label>
              </div> -->
              <a class="forgot-password" @click="navegarARestablecerAcceso"
                >¿Olvidaste tu contraseña?</a
              >
            </div>
            <button type="button" class="login-button bg-btn-exito" @click="handleLogin">
              Iniciar sesión
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import axios from 'axios';

export default {
  name: 'UserLogin',
  data() {
    return {
      email: '',
      password: '',
      emailConfirmed: false,
      showPasswordInput: false,
      animating: false,
      passActive: false,
      alreadyLoggingIn: false,
    };
  },
  methods: {
    ...mapActions(['login']),
    handleFormSubmit() {
      if (!this.emailConfirmed) {
        this.startEmailAnimation();
      } else {
        if (!this.alreadyLoggingIn) {
          this.handleLogin();
        }
      }
    },
    startEmailAnimation() {
      if (this.validateEmail(this.email)) {
        this.animating = true;
      } else {
        alert("Por favor, introduce un correo electrónico válido.");
      }
    },
    validateEmail(email) {
    // Versión simplificada y clara sin escapes innecesarios
    const re = /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    afterEmailFade() {
      this.emailConfirmed = true;
      this.showPasswordInput = true;
      setTimeout(() => {
        this.passActive = true;
      }, 100);
    },
    afterEmailMoveUp() {
      this.animating = false;
    },
    editEmail() {
      this.emailConfirmed = false;
      this.showPasswordInput = false;
    },
    resetAnimation() {
      this.emailConfirmed = false;
      this.showPasswordInput = false;
    },
    handleLogin() {
      if (this.alreadyLoggingIn) return;
      this.alreadyLoggingIn = true;
      
      axios.post('/api/login_post', {
        email: this.email,
        password: this.password,
      }).then(response => {
        console.log("Login response:", response.data);
        if (response.data.success) {
          console.log("Logging in user:", response.data.user);
          this.login(response.data.user).then(() => {
            console.log("Redirecting to dashboard");
            const redirect = '/dashboard';
            this.$router.push(redirect);
          }).catch(error => {
            console.error("Error after login action:", error);
          });
        } else {
          alert(response.data.error);
        }
      }).catch(error => {
        console.error('Error de inicio de sesión:', error);
        alert('Error en el servidor, intenta de nuevo más tarde.');
      }).finally(() => {
          this.alreadyLoggingIn = false; // Restablecer el estado
        });
    },

    goToRegister() {
      this.$router.push('/registrarse');
    },
    ...mapMutations(['setUser']),
  },
}
</script>


<style lang="sass" scoped>
.content-page
  padding-top: 0

.login-page 
  display: flex
  align-items: center
  justify-content: center
  min-height: 100vh
  width: 100vw
  background-color: #f4f6f9
  .login-container 
    display: flex
    flex-wrap: wrap
    width: 100%
    // max-width: 1200px
    background: #fff
    // border-radius: 8px
    overflow: hidden
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
    min-height: 100vh
    @media screen and (max-width: 991px)
      align-items: flex-start
    
    .left-panel 
      background: #D48444
      color: #fff
      display: flex
      align-items: center
      justify-content: center
      text-align: center
      padding: 2rem
      width: 100vw
      max-height: 100px
      box-shadow: 0 10px 10px rgba(155, 155, 155, 0.6)
      @media screen and (min-width: 992px)
        box-shadow: 10px 0 20px rgba(155, 155, 155, 0.6)
        max-height: none
        max-width: 40vw
        flex: 1

      .logo 
        font-size: 2rem
        font-weight: bold
        span 
          display: block
          font-size: 1rem
          font-weight: normal
    .right-panel 
      display: flex
      flex: 1
      padding: 2rem
      justify-content: center
      align-items: center
      width: 100vw
      @media screen and (min-width: 992px)
        max-width: 60vw
      .contenedor-panel-right 
        width: 95%
        max-width: 500px
      .title 
        font-size: 1.5rem
        font-weight: bold
        margin-bottom: 1rem
      .subtitle 
        margin-bottom: 2rem
        .link 
          color: #0052cc
          text-decoration: underline
          cursor: pointer
      .login-form 
        .form-group 
          margin-bottom: 1.5rem
          label 
            display: block
            font-weight: bold
            margin-bottom: 0.5rem
          input 
            width: 100%
            padding: 0.5rem
            border: 1px solid #ccc
            border-radius: 4px
            font-size: 1rem
            &:focus 
              outline: none
              border-color: #0052cc
        .form-footer 
          display: flex
          justify-content: space-between
          align-items: center
          margin-bottom: 1.5rem
          .checkbox-group 
            display: flex
            align-items: center
            input 
              margin-right: 0.5rem
            label 
              font-size: 0.9rem
          .forgot-password 
            font-size: 0.9rem
            color: #0052cc
            cursor: pointer
            text-decoration: underline
            &:hover 
              color: #003c99
        .login-button 
          width: 100%
          padding: 0.75rem
          border: none
          border-radius: 4px
          font-size: 1rem
          cursor: pointer
          text-align: center
</style>