import './assets/main.scss'; // O el nombre de tu archivo de estilos
import { createApp } from 'vue'
import App from './App.vue'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// import './registerServiceWorker'
import router from './router'
import store from './store'
// import { io } from 'socket.io-client';

// Conectar con el servidor de websockets alojado en Vercel
// const socket = io('http://localhost:3001', {
//   transports: ["websocket", "polling"],
//   path: "/socket.io"  
// });



const app = createApp(App)
// app.co nfig.globalProperties.$socket = socket;

app.use(store)
app.use(router)

app.use(Toast, {
    // Opciones globales
    position: 'top-right',
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false
  });


  // Hacer que Toastify esté disponible globalmente
// app.config.globalProperties.$toast = app.config.globalProperties.$toast;

app.mount('#app')
