<template>
  <div class="container">
    <form @submit.prevent="onSubmit" class="form">
      <div class="card">
        <div class="card-title">
          <div class="d-flex align-center justify-center">
            <i class="icon mdi mdi-chat"></i>
            <h3 class="ml-2">Vue Chatapp</h3>
          </div>
        </div>
        <div class="card-text">
          <label for="username" class="label">Username</label>
          <input 
            type="text" 
            id="username" 
            v-model="state.username" 
            class="input" 
            placeholder="Enter your username" 
          />

          <label for="room" class="label">Room</label>
          <select id="room" v-model="state.room" class="select">
            <option v-for="room in rooms" :key="room" :value="room">
              {{ room }}
            </option>
          </select>
        </div>

        <div class="card-actions">
          <button 
            class="btn btn-primary" 
            :disabled="!state.username || !state.room" 
            type="submit"
          >
            Join Chatapp
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'ChatApp',
  setup() {
    const router = useRouter();
    const rooms = ['vue installation', 'vue guide', 'vue api', 'vue examples'];

    const state = reactive({
      username: '',
      room: rooms[0],
    });

    const onSubmit = () => {
      console.log('[SUBMIT]', state);
      router.push(`/chat?username=${state.username}&room=${state.room}`);
    };

    return {
      state,
      rooms,
      onSubmit
    };
  }
};
</script>

<style scoped>
/* General container styles */
.container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.form {
  width: 100%;
  max-width: 600px;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 auto;
}

.card-title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.icon {
  font-size: 24px;
}

.ml-2 {
  margin-left: 8px;
}

.card-text {
  padding-top: 20px;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.input {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.select {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
}

.card-actions {
  text-align: center;
}

.btn {
  width: 100%;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #1976d2;
  color: #fff;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #1565c0;
}

.btn:disabled {
  background-color: #b0bec5;
  cursor: not-allowed;
}
</style>
