<template>
    <div class="login-page">
      <div class="login-container">
        <div class="left-panel">
          <h1 class="logo">GESTION TOTAL <span>ADMIN</span></h1>
        </div>
        <div class="right-panel">
          <div class="contenedor-panel-right">
            <h2 class="title">Crear cuenta</h2>
            <p class="subtitle">
              ¿Ya tienes una cuenta? <a @click="goToLogin" class="link">Inicia sesión</a>
            </p>
            <form @submit.prevent="handleRegister" class="login-form">
              <div class="form-group">
                <label for="nombre">Tu nombre</label>
                <input
                  id="nombre"
                  v-model="nombre"
                  type="text"
                  placeholder="Nombre y apellido"
                />
              </div>
              <div class="form-group">
                <label for="email">Correo electrónico</label>
                <input
                  id="email"
                  v-model="email"
                  type="email"
                  placeholder="ejemplo@mail.com"
                />
              </div>
              <div class="form-group">
                <label for="password">Contraseña</label>
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  placeholder="Debe tener al menos 7 caracteres"
                />
              </div>
              <div class="form-group">
                <label for="confirmPassword">Confirmar contraseña</label>
                <input
                  id="confirmPassword"
                  v-model="confirmPassword"
                  type="password"
                />
              </div>
              <div class="form-group" v-if="isDisabled">
                <label for="clave">Clave de autorización</label>
                <input
                  id="clave"
                  v-model="clave"
                  type="text"
                  :disabled="isDisabled"
                  @input="toUpper"
                />
              </div>
              <button type="submit" class="login-button bg-btn-exito">
                Continuar
              </button>
            </form>
            <p class="terminos">
              Al crear una cuenta aceptas las
              <a href="/condiciones-de-uso">Condiciones de Uso</a> y el
              <a href="/aviso-de-privacidad">Aviso de privacidad</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import axios from 'axios';

export default {
    props: {
        claveProp: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            email: '',
            password: '',
            nombre: '',
            confirmPassword: '',
            clave: '',
        };
    },
    computed: {
        isDisabled() {
            return !!this.claveProp;  // Retorna true si claveProp no es vacío, false de lo contrario
        }
    },
    methods: {
        handleRegister() {
            if (!this.validateEmail(this.email)) {
                alert("Por favor, introduce un correo electrónico válido.");
                return;
            }
            if (this.password !== this.confirmPassword) {
                alert("Las contraseñas no coinciden.");
                return;
            }
            axios.post('/api/register', {
                nombre: this.nombre,
                clave: this.clave,
                email: this.email,
                password: this.password,
            }).then(response => {
                if (response.data.success) {
                    alert('Registro exitoso, ya puedes iniciar sesión');
                    this.goToLogin();
                } else {
                    alert(response.data.error);
                }
            }).catch(error => {
                console.error('Error de registro:', error);
                alert('Hubo un problema con tu solicitud de registro.');
            });
        },
        validateEmail(email) {
            const re = /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        goToLogin() {
            this.$router.push('/login');
        },
        toUpper() {
            this.clave = this.clave.toUpperCase();
        }
    },
    mounted() {
        console.log("Prop value:", this.claveProp); // Esto ayudará a verificar si el valor se pasa correctamente.
        if (this.claveProp) {
            this.clave = this.claveProp.toUpperCase();
        }
    }
}
</script>


<style lang="sass" scoped>
.content-page
  padding-top: 0

.login-page 
  display: flex
  align-items: center
  justify-content: center
  min-height: 100vh
  width: 100vw
  background-color: #f4f6f9
  .login-container 
    display: flex
    flex-wrap: wrap
    width: 100%
    // max-width: 1200px
    background: #fff
    // border-radius: 8px
    overflow: hidden
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
    min-height: 100vh
    @media screen and (max-width: 991px)
      align-items: flex-start
    
    .left-panel 
      background: #D48444
      color: #fff
      display: flex
      align-items: center
      justify-content: center
      text-align: center
      padding: 2rem
      width: 100vw
      max-height: 100px
      box-shadow: 0 10px 10px rgba(155, 155, 155, 0.6)
      @media screen and (min-width: 992px)
        box-shadow: 10px 0 20px rgba(155, 155, 155, 0.6)
        max-height: none
        max-width: 40vw
        flex: 1

      .logo 
        font-size: 2rem
        font-weight: bold
        span 
          display: block
          font-size: 1rem
          font-weight: normal
    .right-panel 
      display: flex
      flex: 1
      padding: 2rem
      justify-content: center
      align-items: center
      width: 100vw
      @media screen and (min-width: 992px)
        max-width: 60vw
      .contenedor-panel-right 
        width: 95%
        max-width: 500px
      .title 
        font-size: 1.5rem
        font-weight: bold
        margin-bottom: 1rem
      .subtitle 
        margin-bottom: 2rem
        .link 
          color: #0052cc
          text-decoration: underline
          cursor: pointer
      .login-form 
        .form-group 
          margin-bottom: 1.5rem
          label 
            display: block
            font-weight: bold
            margin-bottom: 0.5rem
          input 
            width: 100%
            padding: 0.5rem
            border: 1px solid #ccc
            border-radius: 4px
            font-size: 1rem
            &:focus 
              outline: none
              border-color: #0052cc
        .form-footer 
          display: flex
          justify-content: space-between
          align-items: center
          margin-bottom: 1.5rem
          .checkbox-group 
            display: flex
            align-items: center
            input 
              margin-right: 0.5rem
            label 
              font-size: 0.9rem
          .forgot-password 
            font-size: 0.9rem
            color: #0052cc
            cursor: pointer
            text-decoration: underline
            &:hover 
              color: #003c99
        .login-button 
          width: 100%
          padding: 0.75rem
          border: none
          border-radius: 4px
          font-size: 1rem
          cursor: pointer
          text-align: center
</style>